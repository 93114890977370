<template>
  <div class="HomeLayout uk-offcanvas-content">
    <!-- header -->
    <page-header/>
    <!-- content -->
    <main>
      <slot/>
    </main>
    <page-footer/>

    <!-- OFFCANVAS -->
    <!-- <div id="offcanvas-nav" data-uk-offcanvas="overlay: true">
      <div class="uk-offcanvas-bar uk-offcanvas-bar-animation uk-offcanvas-slide">
        <button type="button" class="uk-offcanvas-close uk-close"  data-uk-close></button>
        <ul class="uk-nav uk-nav-default">
          <li>
            <router-link title="market" tag="a" :to="{name: 'mediaintroduction'}">UBT Market
            </router-link>
          </li>
          <li>
            <router-link title="market" tag="a" :to="{name: 'ubtintroduction'}">UBT TUTORIAL EXPERIENCE</router-link>

          </li>
        </ul></div>
    </div> -->

  <div id="offcanvas-nav" uk-offcanvas="overlay: true">
      <div class="uk-offcanvas-bar uk-offcanvas-bar-animation uk-offcanvas-slide">
        <button type="button" class="uk-offcanvas-close uk-close"  data-uk-close></button>
          <ul class="uk-nav uk-nav-primary uk-nav-center uk-margin-auto-vertical">
              <li class="uk-active"><router-link title="Home" tag="a" :to="{name: 'home'}">
                    Home
                  </router-link>
                  <ul class="uk-nav">
                        <li>
                          <a href="https://www.nsdevil.com/">About Us</a>
                        </li>
                        <!-- <li>
                          <router-link title="market" tag="a" :to="{name: 'service'}">Service Inroduction
                          </router-link>
                        </li> -->
                        <li>
                          <a href="https://www.eeobult.org/">EEO-BULT</a>
                        </li>
                        <!-- <li>
                          <router-link title="market" tag="a" :to="{name: 'service'}">Contact Us
                          </router-link>
                        </li> -->
                      </ul>
              </li>
              <li>
                  <router-link title="Home" tag="a" :to="{name: 'home'}">
                    UBT introduction
                  </router-link>
                      <ul class="uk-nav">
<!--                        <li>-->
<!--                          <a href="http://ubt.nsdevil.com/">UBT Introduction</a>-->
<!--                        </li>-->
                        <li>
                          <a href="/uploadingDir/cloud_user_app.apk">UBT Download</a>
                        </li>
                        <!-- <li>
                          <router-link title="market" tag="a" :to="{name: 'help'}">Help
                          </router-link>
                        </li> -->
                      </ul>
                </li>
              <li>
                <router-link title="market" tag="a" :to="{name: 'mediaintroduction'}">UBT Market
                </router-link>
              </li>
               <li>
                  <router-link title="market" tag="a" :to="{name: 'ubtintroduction'}">
                    UBT Tutorial Experience
                  </router-link>
                </li>
                <li>
                  <router-link  v-if="!$store.getters.isLoggedIn"
                  title="login" tag="a" :to="{name: 'login'}">
                  <button type="button" class="uk-button uk-button-primary">Exam code register</button>
                  </router-link>
                </li>
                <li class="uk-nav-divider"></li>
                <li>
                  <router-link
                    v-if="!$store.getters.isLoggedIn"
                    title="Login"
                    tag="a"
                    :to="{name: 'login'}"
                  >Login</router-link>
                </li>
                <li>
                  <router-link
                    v-if="!$store.getters.isLoggedIn"
                    title="Sign Up"
                    tag="a"
                    :to="{name: 'register'}">
                    <span data-uk-icon="icon:sign-up"></span>Sign Up
                  </router-link>
                </li>
              </ul>
      </div>
  </div>

    <!-- /OFFCANVAS -->
    <div id="modal-examcode" uk-modal>
      <div class="uk-modal-dialog uk-modal-body">
        <button class="uk-modal-close-default"  uk-close></button>
        <h2 class="uk-modal-title">비로그인 시험코드 등록</h2>
        <p>시험코드를 등록하시면 응시화면이 출력됩니다.</p>
        <div>
          <form>
            <div class="uk-grid-small uk-flex-center uk-grid" uk-grid>
              <div class="uk-width-expand uk-first-column">
                <div class="uk-inline uk-width-1-1">
                  <span class="uk-form-icon uk-icon" uk-icon="search"></span>
                  <input class="uk-input" type="text" placeholder="시험코드번호 입력" required/>
                </div>
              </div>
              <div>
                <button
                  @click="start"
                  uk-toggle="target: #modal-examcode"
                  class="uk-button uk-button-primary"
                >등록
                </button>
              </div>
            </div>
            <p class="uk-text-small">(안내) 비로그인의 경우 시험이력이 저장되지 않으며 Incorrect answer, Result 서비스가 제공되지 않습니다.</p>
          </form>
        </div>
        <p class="uk-text-right">
          <!-- <button type="button" class="uk-button uk-button-default uk-modal-close" >Cancel</button> -->
        </p>
      </div>
    </div>
  </div>
</template>

<script>
  import PageHeader from '@/components/Homecmp/Header.vue'
  import PageFooter from '@/components/Homecmp/Footer.vue'

  export default {
    name: 'HomeLayout',
    components: {
      PageHeader,
      PageFooter
    },
    data() {
      return {
        user: null
      }
    },
    created() {
    },
    methods: {
      async start() {
        this.$router.push({
          name: 'stexam'
        })
      }
    }
  }
</script>

<style scoped>
</style>
